<template>
  <CModal
    :title="$t('sub_categories')"
    size="xl"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="10">
        <CInput
          :label="$t('parent_category')"
          :placeholder="$t('parent_category')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="modal.data.name_en"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="primary" block @click="showProductCategory()">{{ $t('search') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="8" class="d-flex align-items-center">
        <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="dark" block @click="() => editCategorySortModal = { data: { sortData: $_.sortBy(table.data, 'sort') }, modal: true }">{{ $t('sort') }}</CButton>
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="info" block @click="() => createCategoryModal = { data: { parent: modal.data.id }, modal: true }">{{ $t('create_category') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <v-client-table :columns="table.columns" :data="table.data" :options="table.options">
      <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
        {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
      </CBadge>
      <div slot="action" slot-scope="props" class="text-center">
        <CButtonGroup>
          <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editCategoryModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
          <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteCategoryModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
        </CButtonGroup>
      </div>
    </v-client-table>
    <div slot="footer-wrapper"></div>

    <CreateCategoryModal :data="createCategoryModal.data" :show="createCategoryModal.modal" @showUpdate="show => createCategoryModal.modal = show" />

    <EditCategoryModal :data="editCategoryModal.data" :show="editCategoryModal.modal" @showUpdate="show => editCategoryModal.modal = show" />

    <DeleteCategoryModal :data="deleteCategoryModal.data" :show="deleteCategoryModal.modal" @showUpdate="show => deleteCategoryModal.modal = show" />

    <EditCategorySortModal :data="editCategorySortModal.data" :show="editCategorySortModal.modal" @showUpdate="show => editCategorySortModal.modal = show" />

  </CModal>
</template>

<script>
import CreateCategoryModal from '../modal/CreateCategoryModal'
import EditCategoryModal from '../modal/EditCategoryModal'
import DeleteCategoryModal from '../modal/DeleteCategoryModal'
import EditCategorySortModal from '../modal/EditCategorySortModal'

export default {
  name: 'SubCategoriesModal',
  components: {
    CreateCategoryModal,
    EditCategoryModal,
    DeleteCategoryModal,
    EditCategorySortModal
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      table: {
        columns: [ 'name_en', 'name_zh', 'sort', 'enabled', 'action' ],
        data: [],
        options: {
          headings: {
            name_en: this.$t('name') + ' (EN)',
            name_zh: this.$t('name') + ' (ZH)',
            sort: this.$t('sort'),
            enabled: this.$t('enable'),
            action: this.$t('action')
          },
          orderBy: { ascending: true, column: 'sort' },
          sortable: [ 'name_en', 'name_zh', 'sort', 'enabled' ],
          filterable: [ 'name_en', 'name_zh' ]
        },
      },
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      createCategoryModal: { data: {}, modal: false },
      editCategoryModal: { data: {}, modal: false },
      deleteCategoryModal: { data: {}, modal: false },
      editCategorySortModal: { data: {}, modal: false },
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.searchData = { id: this.modal.data.id }
        this.showProductCategory()
      }
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    showProductCategory () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('showProductCategory', this.$_.clone(this.searchData)).then(res => {
        this.modal.data = res[0]
        this.table.data = res[0].sub_categories
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res[0].sub_categories.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
  },
  beforeDestroy: function () {
  },
}
</script>
