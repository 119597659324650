<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10">
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getProductCategories()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="8" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="dark" block @click="() => editCategorySortModal = { data: { sortData: $_.sortBy(data, 'sort') }, modal: true }">{{ $t('sort') }}</CButton>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createCategoryModal = { modal: true }">{{ $t('create_category') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => subCategoriesModal = { data: props.row, modal: true }">{{ $t('sub_categories') }}</CButton>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editCategoryModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteCategoryModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <SubCategoriesModal :data="subCategoriesModal.data" :show="subCategoriesModal.modal" @showUpdate="show => subCategoriesModal.modal = show" />

      <CreateCategoryModal :data="createCategoryModal.data" :show="createCategoryModal.modal" @showUpdate="show => createCategoryModal.modal = show" />

      <EditCategoryModal :data="editCategoryModal.data" :show="editCategoryModal.modal" @showUpdate="show => editCategoryModal.modal = show" />

      <DeleteCategoryModal :data="deleteCategoryModal.data" :show="deleteCategoryModal.modal" @showUpdate="show => deleteCategoryModal.modal = show" />

      <EditCategorySortModal :data="editCategorySortModal.data" :show="editCategorySortModal.modal" @showUpdate="show => editCategorySortModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import SubCategoriesModal from './modal/SubCategoriesModal'
import CreateCategoryModal from './modal/CreateCategoryModal'
import EditCategoryModal from './modal/EditCategoryModal'
import DeleteCategoryModal from './modal/DeleteCategoryModal'
import EditCategorySortModal from './modal/EditCategorySortModal'

export default {
  name: 'categories',
  components: {
    SubCategoriesModal,
    CreateCategoryModal,
    EditCategoryModal,
    DeleteCategoryModal,
    EditCategorySortModal
  },
  data () {
    return {
      columns: [ 'name_en', 'name_zh', 'sort', 'enabled', 'action' ],
      data: [],
      options: {
        headings: {
          name_en: this.$t('name') + ' (EN)',
          name_zh: this.$t('name') + ' (ZH)',
          sort: this.$t('sort'),
          enabled: this.$t('enable'),
          action: this.$t('action')
        },
        orderBy: { ascending: true, column: 'sort' },
        sortable: [ 'name_en', 'name_zh', 'sort', 'enabled' ],
        filterable: [ 'name_en', 'name_zh' ]
      },
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      subCategoriesModal: { data: {}, modal: false },
      createCategoryModal: { data: {}, modal: false },
      editCategoryModal: { data: {}, modal: false },
      deleteCategoryModal: { data: {}, modal: false },
      editCategorySortModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    getProductCategories () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getProductCategories', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
