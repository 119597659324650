<template>
  <CModal
    :title="$t('sort')"
    color="dark"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <p class="text-muted pl-2">{{ $t('drag_text_msg') }}</p>
    <CRow>
      <CCol col="12" sm="12">
        <draggable
          class="list-group"
          tag="ul"
          v-model="formData"
          v-bind="dragOptions"
        >
          <li
            class="list-group-item"
            v-for="(e, index) in formData"
            :key="index"
          >
            <CRow>
              <CCol col="12" sm="12">
                {{ e.name_en }}
              </CCol>
            </CRow>
          </li>
        </draggable>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<style>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group-item {
  cursor: move;
}
.list-group-img {
  max-width: 100%;
  max-height: 200px;
}
</style>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'EditCategorySortModal',
  components: {
    draggable
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      },
      formData: [],
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.formData = this.modal.data.sortData
      }
    }
  },
  computed: {
    valid_formData_length () {
      return this.formData.length > 0 ? true : false
    },
    valid_submit () {
      return this.valid_formData_length
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateProductCategorySort', { product_categories: this.$_.pluck(this.formData, 'id') }).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
